<template>
    <div>


    </div>
</template>

<script>
    export default {
        name: "Etc"
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/etc";
</style>
